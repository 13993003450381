import React from 'react'
import styled from 'styled-components'

import { LargeP } from 'src/components/shared/text'
import { fonts, colors } from 'src/styles/variables'
import { media } from 'src/styles/util'
import DottedText from '../shared/DottedText'

const Grid = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  margin: 0 auto 80px auto;
  ${media.mobile`
    grid-template-columns: 1fr;
    grid-gap: 40px;
    padding: 0 24px;
  `}
  ${media.tablet`
    grid-template-columns: 465px;
    grid-gap: 50px;
    justify-content: center;
  `}
  ${media.desktop`
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr;
    max-width: 1200px;
    padding: 0 40px;
  `}
`

const TextWrapper = styled.div`
  margin: 0 auto;
  ${media.mobile`
    text-align: center;
  `};
  ${media.tablet`
    text-align: left;
    max-width: 470px;
  `};
  ${media.desktop`
    max-width: 430px;
  `};
`

const RightGroup = styled.div`
  width: 100%;
  margin: 0 auto;
  ${media.desktop`
    max-width: 500px;
  `};
`

const RightTitle = styled(LargeP)`
  font-family: ${fonts.larsseit.bold};
`

const PetNameSpan = styled.span`
  text-transform: capitalize;
`

interface PlanSummaryProps {
  firstName: string
  petName: string
  supplementText: string
  breedName: string
  ageText: string
  weightText: string
  recommendationsText: string
  recommendedIntervalText: string
}

const PlanSummary = ({ planSummaryInfo }: { planSummaryInfo: PlanSummaryProps }) => {
  const {
    firstName,
    petName,
    supplementText,
    breedName,
    ageText,
    weightText,
    recommendationsText,
    recommendedIntervalText
  } = planSummaryInfo

  const rows = [
    ['Breed', breedName],
    ['Age', ageText],
    ['Weight', weightText],
    ['Recommendations', recommendationsText],
    ['Recommended Subscription (Based on Weight)', recommendedIntervalText]
  ]

  return (
    <Grid>
      <TextWrapper>
        <LargeP>
          {firstName}, here’s an overview of the <strong>{supplementText}</strong> we’ve chosen for {petName} and how they can help, based
          on the answers you gave us!
        </LargeP>
      </TextWrapper>
      <RightGroup>
        <RightTitle>
          Summary of <PetNameSpan>{petName}</PetNameSpan>’s Plan
        </RightTitle>
        {rows.map(row => (
          <DottedText left={row[0]} right={row[1]} bg={colors.lightText} key={row[0]} />
        ))}
      </RightGroup>
    </Grid>
  )
}

export default PlanSummary
