import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { gsap, Power3 } from 'gsap'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Headline, LargeP, P, MonoP } from 'src/components/shared/text'
import { PrimaryButton } from 'src/components/shared/buttons'
import { fonts, colors } from 'src/styles/variables'
import { media } from 'src/styles/util'
import ExpandableGroup from './ExpandableGroup'

const Container = styled.section`
  margin: 20px auto 0 auto;
  z-index: 1;
  ${media.mobile`
    padding: 0 16px;
  `}
  ${media.tablet`
    padding: 0 20px;
  `}
  ${media.desktop`
    max-width: 1200px;
    padding: 0 40px;
  `}
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  margin: 0 auto 20px auto;
  grid-gap: 24px;
  ${media.mobile`
    grid-template-columns: 1fr;
  `}
  ${media.tablet`
    grid-template-columns: 1fr 1fr;
  `}
`

const Card = styled.div`
  width: 100%;
  background-color: ${colors.white};
  border-radius: 25px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: relative;
  ${media.mobile`
    padding: 60px 30px;
    height: 620px;
  `}
  ${media.tablet`
    padding: 80px 30px 60px 30px;
    height: 600px;
  `}
  ${media.desktop`
    padding: 60px;
    height: 675px;
  `}
`

const CardCount = styled.div`
  background-color: ${colors.lightGrey};
  color: ${colors.navy};
  height: 43px;
  width: 43px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  position: absolute;
  top: 27px;
  left: 21px;
`

const Title = styled(LargeP)`
  font-family: ${fonts.larsseit.bold};
  text-transform: capitalize;
`

const Consideration = styled.div`
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
  max-width: 400px;
  padding: 0 3px;
`

const Considerations = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  padding-bottom: 60px;
  padding-top: 10px;
  overflow-y: auto;
`

const ActionRow = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 0 43px 36px 43px;
`

const BigArrow = styled.span`
  font-size: 20px;
`

const ActionText = styled(MonoP)`
  color: ${colors.slate};
  text-align: center;
`

const ActionButton = styled(ActionText)`
  cursor: pointer;
  &:hover {
    color: ${colors.navy};
  }
`

const ExpandButton = styled('div')<{ expanded?: boolean }>`
  width: 100%;
  background-color: ${(props) => (props.expanded ? colors.lightText : 'white')};
  display: grid;
  place-items: center;
  border-radius: 25px;
  margin-bottom: 20px;
  height: 55px;
  cursor: ${(props) => (props.expanded ? 'default' : 'pointer')};
  color: ${(props) => (props.expanded ? colors.lightText : colors.navy)};
  transition: 150ms all ease-in-out;
  &:hover {
    transform: ${(props) => (props.expanded ? 'none' : 'scale(1.03)')};
  }
`

const ExpandableWrapper = styled.div`
  width: 100%;
  height: 0;
  overflow: hidden;
  opacity: 0;
`

const ImgWrapper = styled.div`
  display: grid;
  place-items: center;
  margin: 0 auto;
  ${media.mobile`
    height: 225px;
    width: 225px;
  `}
  ${media.tablet`
    height: 200px;
    width: 200px;
  `}
  ${media.desktop`
    height: 275px;
    width: 265px;
  `}
`
const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop)

interface CardGroupInterface {
  idx: number
  recommendation: any
  product: any
  petName: string
  weightText: string
  count: number
  checkoutUrl: string
  data: any
}

const CardGroup = (props: CardGroupInterface) => {
  const { idx, recommendation, product, petName, weightText, count, checkoutUrl, data } = props
  const [active, setActive] = useState(1)
  const [expanded, setExpanded] = useState(false)
  const scrollRef = useRef(null)

  const className = `expandableWrapper_${idx}`

  const handleExpand = () => {
    if (!expanded) {
      gsap.to(`.${className}`, { opacity: 1, height: 1880, duration: 1, ease: Power3.easeInOut })
      gsap.to(`.${className}`, { opacity: 1, delay: 0.5 })
      setExpanded(true)
    }
  }

  const handleClose = () => {
    scrollToRef(scrollRef)
    gsap.fromTo(`.${className}`, { height: 1880 }, { height: 0, duration: 2, ease: Power3.easeOut })
    gsap.fromTo(`.${className}`, { opacity: 1 }, { opacity: 0 })
    setExpanded(false)
  }

  const oneLiner = `Since ${petName} is ${weightText}, we recommend ${count} soft chew${
    count > 1 ? 's' : ''
  } per day for optimum nutrition.`

  const ingredients = data.ingredients[recommendation.name]
  const numberOfCards = ingredients.length + 1

  return (
    <>
      <Container>
        <Grid>
          <Card>
            <CardCount>{idx + 1}</CardCount>
            <Headline>{product.title}</Headline>
            <ImgWrapper>
              <GatsbyImage
                image={getImage(data[recommendation.name])}
                alt={`${product.title} tin`}
                style={{ height: '100%', width: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </ImgWrapper>
            <P>{product.shortDescription}</P>
            <a href={checkoutUrl}>
              <PrimaryButton
                copy="Checkout Now"
                bg={colors[product.colors.bg]}
                color={colors[product.colors.color]}
                arrow
              />
            </a>
          </Card>
          <Card ref={scrollRef}>
            <Title>How will this help {petName}?</Title>

            <Considerations>
              <div>
                {active === 1 ? (
                  recommendation.healthConsiderations.map((item: any) => (
                    <Consideration key={item.consideration}>
                      <Title>{item.consideration}</Title>
                      <P>{item.copy}</P>
                    </Consideration>
                  ))
                ) : (
                  <Consideration>
                    <br />
                    <ImgWrapper>
                      <GatsbyImage
                        image={getImage(ingredients[active - 2].img)}
                        alt={ingredients[active - 2].name}
                        style={{ height: '100%', width: '100%' }}
                        imgStyle={{ objectFit: 'contain' }}
                      />
                    </ImgWrapper>
                    <br />
                    <Title>{ingredients[active - 2].name}</Title>
                    <P>{ingredients[active - 2].description}</P>
                  </Consideration>
                )}
              </div>
            </Considerations>

            <ActionRow>
              {active > 1 ? (
                <ActionButton onClick={() => setActive(active - 1)} style={{ textAlign: 'left' }}>
                  <BigArrow>￩</BigArrow> BACK
                </ActionButton>
              ) : (
                <div />
              )}
              <ActionText>
                {active}/{numberOfCards}
              </ActionText>
              {active < numberOfCards ? (
                <ActionButton onClick={() => setActive(active + 1)} style={{ textAlign: 'right' }}>
                  NEXT <BigArrow>￫</BigArrow>
                </ActionButton>
              ) : (
                <div />
              )}
            </ActionRow>
          </Card>
        </Grid>
        <ExpandButton onClick={handleExpand} expanded={expanded}>
          <LargeP>More Info About {product.title} +</LargeP>
        </ExpandButton>
      </Container>
      <ExpandableWrapper className={className}>
        <ExpandableGroup
          close={handleClose}
          petName={petName}
          oneLiner={oneLiner}
          product={product}
        />
      </ExpandableWrapper>
    </>
  )
}

export default CardGroup
