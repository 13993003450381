import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { H1 } from 'src/components/shared/text'
import { media } from 'src/styles/util'

const Wrapper = styled.div`
  display: grid;
  justify-items: center;
  align-items: end;
  width: fit-content;
  ${media.mobile`
    grid-template-columns: 1fr;
    margin: 50px auto 40px auto;
  `}
  ${media.tablet`
    grid-template-columns: auto auto;
    grid-column-gap: 36px;
    margin: 80px auto 60px auto;
  `}
  ${media.desktop`
    margin: 100px auto 90px auto;
  `}
`

const ImgWrapper = styled.div`
  ${media.mobile`
    height: 100px;
    width: 100px;
    padding-bottom: 30px;
  `}
  ${media.tablet`
    padding-bottom: 10px;
  `}
  ${media.desktop`
    height: 196px;
    width: 196px;
    padding-bottom: 10px;
  `}
`

const query = graphql`
  query {
    dog: file(relativePath: { regex: "/sitting-dog-large.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 160)
      }
    }
  }
`

const Header = ({ petName }: { petName: string }) => {
  const images = useStaticQuery(query)
  return (
    <Wrapper>
      <ImgWrapper>
        <GatsbyImage
          image={getImage(images.dog)}
          alt="Dog sitting"
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
        />
      </ImgWrapper>
      <H1>{petName}’s Plan</H1>
    </Wrapper>
  )
}

export default Header
