import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useLockBodyScroll } from 'react-use'
import { useRecoilState } from 'recoil'

import CartDrawer from '../NavBar/CartDrawer'
import Spinner from '../shared/Spinner'

import { NavButton } from '../shared/buttons'
import { MonoP, InternalLink } from '../shared/text'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

import useQuantity from '../../hooks/useQuantity'
import { storeState } from '../../actions/checkout'

const Nav = styled.header`
  width: 100%;
`

const HeaderInner = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  margin: 0 auto;
  ${media.mobile`
    padding: 16px 6px 16px 14px;
    grid-template-columns: 82px 1fr;
  `}
  ${media.tablet`
    padding: 25px 10px 25px 30px;
    grid-template-columns: 1fr 105px 1fr;
  `}
  ${media.desktop`
    padding: 25px 40px;
    grid-template-rows: 45px;
  `}
`

const LogoLink = styled(InternalLink)`
  transition: 200ms transform ease-in-out;
  text-align: left;
  height: auto;
  &:hover {
    transform: scale(1.05);
  }
  ${media.mobile`
    width: 82px;
    grid-column: 1;
  `}
  ${media.tablet`
    width: 100px;
    grid-column: 2;
  `}
`

const RightGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Count = styled(MonoP)`
  color: ${colors.navy};
`

const CartCounter = styled.div`
  border-radius: 50%;
  border: 1px solid ${colors.navy};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 250ms border-color ease-in-out;
  position: relative;
  ${media.mobile`
    margin-right: 12px;
    height: 36px;
    width: 36px;
  `}
  ${media.tablet`
    margin-right: 12px;
    height: 45px;
    width: 45px;
  `}
  ${media.desktop`
    margin-right: 20px;
  `}
  &:hover {
    background-color: ${colors.navy};
    & ${Count} {
      transition: 200ms color ease-in-out;
      color: ${colors.lightText};
    }
  }
`

const TextButton = styled(MonoP)`
  font-size: 16px;
  cursor: pointer;
  color: ${colors.navy} !important;
  &:hover {
    color: ${colors.navy} !important;
  }
  ${media.mobile`
    display: none;
  `}
  ${media.tablet`
    display: block;
  `}
`

const BigArrow = styled.span`
  font-size: 18px;
  padding-right: 6px;
  text-decoration: none !important;
`

const query = graphql`
  query {
    logoImg: file(relativePath: { regex: "/logo-navy.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 105)
      }
    }
  }
`

const NavBar = ({ checkoutUrl }: { checkoutUrl: string }) => {
  const images = useStaticQuery(query)
  const [store] = useRecoilState(storeState)
  const [, quantity] = useQuantity(store.checkout?.lineItems || [])
  const [cartDrawerVisible, setCartDrawerVisible] = useState(false)

  useLockBodyScroll(cartDrawerVisible)

  return (
    <Nav>
      <HeaderInner>
        <InternalLink to="/">
          <TextButton>
            <BigArrow>￩</BigArrow>Home
          </TextButton>
        </InternalLink>{' '}
        <LogoLink to="/">
          <GatsbyImage
            image={getImage(images.logoImg)}
            alt="Finn logo"
            style={{ height: '100%', justifyContent: 'flex-start' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </LogoLink>
        <RightGroup>
          {quantity === 0 ? null : (
            <CartCounter onClick={() => setCartDrawerVisible(true)} className="NavBar_CartCounter">
              {store.fetching ? (
                <Spinner overlay size={30} color={colors.navy} />
              ) : (
                <Count>{quantity}</Count>
              )}
            </CartCounter>
          )}
          {checkoutUrl && (
            <a href={checkoutUrl}>
              <NavButton copy="Checkout Now" color={colors.orange} />
            </a>
          )}
        </RightGroup>
      </HeaderInner>
      <CartDrawer visible={cartDrawerVisible} close={() => setCartDrawerVisible(false)} />
    </Nav>
  )
}

export default NavBar
