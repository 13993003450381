import React from 'react'
import styled from 'styled-components'

import Dosage from 'src/components/shared/Dosage'
import DottedText from 'src/components/shared/DottedText'
import { Headline, H6, LargeP, P, SmallP, MonoP } from 'src/components/shared/text'
import { fonts, colors } from 'src/styles/variables'
import { media } from 'src/styles/util'

const Container = styled.div`
  width: 100%;
  background-color: ${colors.white};
`

const Content = styled.div`
  margin: 0 auto;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.tablet`
    max-width: 1200px;
    padding: 0 40px;
  `}
`

const Close = styled(P)`
  color: ${colors.slate};
  margin: 20px auto;
  cursor: pointer;
  &:hover {
    color: ${colors.navy};
  }
`

const Title = styled(Headline)`
  text-align: center;
  ${media.mobile`
    margin: 30px auto 60px auto;
    padding: 0 36px;
  `}
  ${media.tablet`
    max-width: 700px;
    margin: 60px auto 80px auto;
    padding: 0;
  `}
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 30px;
  margin-bottom: 50px;
  ${media.mobile`
    grid-template-columns: 1fr;
    padding: 0 36px;
  `}
  ${media.tablet`
    grid-template-columns: 1fr 1fr;
    padding: 0;
  `}
`

const MonoTitle = styled(MonoP)`
  border-top: 1px solid ${colors.navy};
  width: 100%;
  padding: 14px 0 30px 0;
`

const Quote = styled(H6)`
  &:before {
    content: '“';
    position: absolute;
    margin-left: -18px;
    ${media.mobile`
      margin-left: -14px;
    `}
    ${media.tablet`
      margin-left: -18px;
    `}
  }
  ${media.tablet`
    padding-right: 40px;
  `}
`

const VetName = styled(MonoP)`
  color: ${colors.slate};
  ${media.mobile`
    margin: 20px 0 40px 0;
  `}
  ${media.tablet`
    margin: 30px 0 0 0;
  `}
  ${media.desktop`
    margin: 67px 0 0 0;
  `}
`

const Subheader = styled(LargeP)`
  font-family: ${fonts.larsseit.bold};
`

const InactiveIngredients = styled(SmallP)`
  max-width: 425px;
`

interface ExpandableGroupInterface {
  close: () => void
  petName: string
  oneLiner: string
  product: any
}

const ExpandableGroup = ({ close, petName, oneLiner, product }: ExpandableGroupInterface) => {
  const activeIngredients = product.card3[0].copy[0].ingredients
  const inactiveIngredients = product.card3[0].copy[1]

  return (
    <Container>
      <Content>
        <Close onClick={close}>Close ×</Close>
        <Title>{product.oneLiner}</Title>
        <Grid>
          {product.vetQuote && (
            <div>
              <MonoTitle>Words from the vet</MonoTitle>
              <Quote>{product.vetQuote.quote}”</Quote>
              <VetName>- {product.vetQuote.author}</VetName>
            </div>
          )}
          <div>
            <MonoTitle>Ingredients</MonoTitle>
            <Subheader>Active Ingredients Per Soft Chew</Subheader>
            {inactiveIngredients?.body &&
              activeIngredients.map((ingredient: any) => (
                <DottedText
                  key={ingredient.name}
                  left={ingredient.name}
                  right={ingredient.amount}
                />
              ))}
            <br />
            <br />
            <Subheader>Inactive Ingredients</Subheader>
            {inactiveIngredients?.body && (
              <InactiveIngredients>{inactiveIngredients?.body}</InactiveIngredients>
            )}
          </div>
        </Grid>
        <Dosage
          petName={petName}
          oneLiner={oneLiner}
          bg={colors[product.colors.bg]}
          color={colors[product.colors.color]}
        />
        <Close onClick={close}>×</Close>
      </Content>
    </Container>
  )
}

export default ExpandableGroup
