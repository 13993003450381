import HipAndJoint from '../data/products/hip-and-joint.json'
import CalmingAid from '../data/products/calming-aid.json'
import Multivitamin from '../data/products/multivitamin.json'
import SkinAndCoat from '../data/products/skin-and-coat.json'
import Probiotics from '../data/products/digestive-probiotics.json'
import AllergyAndItch from '../data/products/allergy-and-itch.json'
import AnalGlandSupport from '../data/products/anal-gland-support.json'
import VisionAndTear from '../data/products/vision-and-tear-stain.json'

interface BasicProps {
  [key: string]: string
}

interface ProdDetailProps {
  [key: string]: any
}

// maps the recommendation SKUs (stored in API database) to the productAtom slugs (Storefront API)
export const recommendationToProductSlug: BasicProps = {
  multivitamin: 'multivitamin',
  skin_coat: 'skin-and-coat',
  hip_joint: 'hip-and-joint',
  calming_aid: 'calming-aid',
  digestive_probiotics: 'digestive-probiotics',
  allergy_itch: 'allergy-and-itch',
  anal_gland_support: 'anal-gland-support',
  vision_tear: 'vision-and-tear-stain'
}

export const recommendationToProductName: BasicProps = {
  multivitamin: 'Multivitamin',
  skin_coat: 'Skin & Coat',
  hip_joint: 'Hip & Joint',
  calming_aid: 'Calming Aid',
  digestive_probiotics: 'Digestive Probiotics',
  allergy_itch: 'Allergy & Itch',
  anal_gland_support: 'Anal Gland Support',
  vision_tear: 'Vision & Tear Stain'
}

export const recommendationToProductDetail: ProdDetailProps = {
  multivitamin: Multivitamin,
  skin_coat: SkinAndCoat,
  hip_joint: HipAndJoint,
  calming_aid: CalmingAid,
  digestive_probiotics: Probiotics,
  allergy_itch: AllergyAndItch,
  anal_gland_support: AnalGlandSupport,
  vision_tear: VisionAndTear,

  'skin-coat': SkinAndCoat,
  'hip-and-joint': HipAndJoint,
  'calming-aid': CalmingAid,
  'digestive-probiotics': Probiotics,
  'allergy-and-itch': AllergyAndItch,
  'anal-gland-support': AnalGlandSupport,
  'vision-and-tear-stain': VisionAndTear
}
