import React from 'react'
import { PageProps } from 'gatsby'
import { parse } from 'query-string'

import Layout from 'src/layouts'
import Dashboard from 'src/components/Dashboard'
import SEO from 'src/components/shared/SEO'

const DashboardPage: React.FC<PageProps> = ({ location }) => {
  const { id } = parse(location.search)

  return (
    <>
      <SEO title="Dashboard" location={location} />

      <Layout location={location}>
        <Dashboard id={id} />
      </Layout>
    </>
  )
}

export default DashboardPage
