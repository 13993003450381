import React from 'react'
import styled from 'styled-components'

import { PrimaryButton } from 'src/components/shared/buttons'
import { Headline, LargeP } from 'src/components/shared/text'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'

const Container = styled.div`
  width: 100%;
  background-color: ${colors.orange};
  ${media.mobile`
    padding: 80px 40px 80px 40px;
    margin-top: 45px;
  `}
  ${media.tablet`
    padding: 121px 40px 80px 40px;
    margin-top: 45px;
  `}
`

const Content = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const StyledP = styled(LargeP)`
  ${media.mobile`
    margin: 31px auto;
  `}
  ${media.tablet`
    max-width: 858px;
    margin: 31px auto 64px auto;
  `}
`

interface CtaGroupInterface {
  checkoutUrl: string
  headline: string
  bodyText: string
}

const CtaGroup = ({ checkoutUrl, headline, bodyText }: CtaGroupInterface) => (
  <Container>
    <Content>
      <Headline>{headline}</Headline>
      <StyledP>{bodyText}</StyledP>
      {checkoutUrl && (
        <a href={checkoutUrl}>
          <PrimaryButton copy="Checkout Now" arrow />
        </a>
      )}
    </Content>
  </Container>
)

export default CtaGroup
